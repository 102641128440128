import userRoutes from '@mv-submodules/inplant-user-fe/ui/routes'
import { routes as checklistRoutes } from '@mv-submodules/inplant-checklist-fe-iblu/index'
import attachInplantWrapperTo from '../../inplant-coreadapter-fe/ui/components/widgets/InplantWrapper/InplantWrapperHOC'
import { RoutePropsCoreWithLegacy, RoutePropsLegacy } from '../../inplant-coreadapter-fe/types/routes'
import { mapRoutePropsLegacyToRoutePropsCoreWithLegacy } from '../../inplant-coreadapter-fe/functions/routeAdapter'
import { generateRoutes } from '../../inplant-chronoframe-fe'
import { routes as assetManagerRoutes } from '../../inplant-asset-manager-fe'

const userCan = (roles: string[] | boolean, userRoles: string[]) => {
  if (typeof roles === 'boolean') {
    return roles
  }
  return userRoles.filter(value => -1 !== roles.indexOf(value)).length > 0
}

const userCant = (roles: string[] | boolean, userRoles: string[]) => !userCan(roles, userRoles)

const composeRoutes = (moduleRoutes: RoutePropsLegacy[], userRoles: string[]) => {
  return moduleRoutes.map(routeGroup => {
    const aclRoute = routeGroup

    // * -------------------------------- MODULE --------------------------------
    switch (routeGroup.path) {
      // * CHECKLIST
      case '/checklist/models-assets':
        aclRoute.visible = userCan(true, userRoles)
        break

      // * SCADENZARIO
      case '/scadenziario/ongoing':
        aclRoute.visible = userCant(['EST', 'ESTMAN', 'ESTMAG', 'PRE', 'SEL', 'ALI'], userRoles)
        break
      // * ANOMALIE
      case '/anomalie/anomalie-ongoing':
        aclRoute.visible = userCant(['EST', 'ESTMAG'], userRoles)
        break
    }

    // root's childs visibility, should include all child acls
    // * -------------------------------- MODULE SECTION --------------------------------
    if (routeGroup.children) {
      aclRoute.children = routeGroup.children.map((childRoute: RoutePropsLegacy) => {
        switch (childRoute.path) {
          // * CHECKLIST
          case '/checklist/models-assets':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/models':
            childRoute.visible = userCan(true, userRoles)
            break

          case '/checklist/archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS',
                'SVA',
                'PRE',
                'SEL',
                'ALI',
              ],
              userRoles
            )
            break

          case '/checklist/setup':
            childRoute.visible = userCan(['SVA', 'UTM'], userRoles)
            break

          case '/checklist/personal-archive':
            childRoute.visible = userCan(
              [
                'RCP',
                'RPROD',
                'VRPROD',
                'RMAG',
                'DID',
                'REA',
                'RSPP',
                'ASPP',
                'CI',
                'ASQ',
                'RSACP',
                'UTM',
                'RMAN',
                'SACP',
                'CSS',
                'PRE',
                'SEL',
                'ALI',
              ],
              userRoles
            )
            break

          case '/checklist/validations':
            childRoute.visible = userCan(['RCP', 'RPROD', 'MAG', 'RMAG', 'ASPP', 'ASQ'], userRoles)
            break

          case '/checklist/reset-asset-hours':
            childRoute.visible = userCan(['RSACP', 'SACP', 'RCP', 'UTM', 'PRE', 'SEL', 'ALI'], userRoles)
            break

          case '/checklist/gia':
            childRoute.visible = userCan(
              ['RMAG', 'RPROD', 'VRPROD', 'SACP', 'RSACP', 'DID', 'REA', 'MAG', 'SVA'],
              userRoles
            )
            break
        }
        return childRoute
      })
    }
    return aclRoute
  })
}

export const aclRoutes = (sroutes: RoutePropsCoreWithLegacy[], forbiddenActions: string[], loginSuccessPath: string) =>
  sroutes.filter(r => r)

export const aclRedirect = (loginSuccessPath: string) => loginSuccessPath

const routes: (userRoles: string[]) => RoutePropsCoreWithLegacy[] = userRoles => {
  const result = [
    ...composeRoutes(
      [
        attachInplantWrapperTo(checklistRoutes),
        attachInplantWrapperTo(
          generateRoutes('scadenziario', ['ongoing', 'overdue', 'user-tasks', 'to-validate', 'archive', 'rejected'])
        ),
        attachInplantWrapperTo(
          generateRoutes('anomalie', ['anomalie-ongoing', 'anomalie-overdue', 'anomalie-archive', 'anomalie-rejected'])
        ),
        attachInplantWrapperTo(userRoutes),
        attachInplantWrapperTo(assetManagerRoutes),
      ],
      userRoles
    ).map(mapRoutePropsLegacyToRoutePropsCoreWithLegacy),
  ]
  return result
}

export default routes
