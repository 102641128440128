import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { withTranslation, WithTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Tooltip } from 'react-tippy'
import Image, { InfoImage } from '../../widgets/Image/Image'
import { checklistPhotoModels, checklistPhotos } from '../../../../redux/actions/checklistPhotos'
import {
  ChecklistPhotoModel,
  ChecklistPhoto,
  SliderPhoto,
  ChecklistDatePhotos,
} from '../../../../types/checklistPhotos'
import ChecklistPhotosModal from './ChecklistPhotosModal'
import { SliderArrowAll } from './SliderArrow'
import { Alert, Filters, PageHeader } from '@mv-submodules/inplant-components-fe'
import { FilterComponent } from '@mv-submodules/inplant-components-fe/ui/components/Filters/Filters'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'

interface OwnProps extends RouteComponentProps<any> {}

interface DispatchProps {
  fetchPhotoModels: () => Promise<ChecklistPhotoModel[]>
  fetchPhotos: (modelId: string, date: string) => Promise<ChecklistPhoto[]>
}

type Props = OwnProps & DispatchProps & WithTranslation

interface OwnState {
  photoModelId: string | null
  date: string | null
  isFetching: boolean
  photoModels: ChecklistPhotoModel[]
  photos: ChecklistDatePhotos[]
  infoImages: { [imagePath: string]: Partial<InfoImage> }
  slidesToShow: number
  carouselRef: React.RefObject<any>
  modalSliderRef: React.RefObject<any>
  modalPhotos: SliderPhoto[] | null
  modalPhotosTitle: string | null
  modalPhotoIndex: number | null
  error: string | null
}

const mapStateToProps = null

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  fetchPhotoModels: () => dispatch(checklistPhotoModels()),
  fetchPhotos: (modelId, date) => dispatch(checklistPhotos(modelId, date)),
})

const today = mvDate.format(mvDate.now(), 'dd/MM/yyyy')

class ChecklistPhotos extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      photoModelId: null,
      date: today,
      isFetching: false,
      photoModels: [],
      photos: [],
      slidesToShow: 5,
      carouselRef: React.createRef(),
      modalSliderRef: React.createRef(),
      modalPhotos: null,
      modalPhotosTitle: null,
      modalPhotoIndex: null,
      error: null,
      infoImages: {},
    }
    this.handleModelChange = this.handleModelChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleError = this.handleError.bind(this)
    this.fetchPhotos = this.fetchPhotos.bind(this)
    this.slickAllToNext = this.slickAllToNext.bind(this)
    this.slickAllToPrev = this.slickAllToPrev.bind(this)
    this.openPhotosInModal = this.openPhotosInModal.bind(this)
    this.closePhotosModal = this.closePhotosModal.bind(this)
    this.updateCarouselSlidesToShow = this.updateCarouselSlidesToShow.bind(this)
  }

  public componentDidMount() {
    this.setState({ isFetching: true })
    this.props
      .fetchPhotoModels()
      .then((photoModels: ChecklistPhotoModel[]) =>
        this.setState({
          isFetching: false,
          photoModels,
          photoModelId: photoModels && photoModels[0] ? photoModels[0].id : null,
        })
      )
      .then(this.fetchPhotos)
      .catch(this.handleError)

    this.updateCarouselSlidesToShow()
    window.addEventListener('resize', this.updateCarouselSlidesToShow)
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateCarouselSlidesToShow)
  }

  public render() {
    const SliderArrowAllNext = SliderArrowAll(this.slickAllToNext)
    const SliderArrowAllPrev = SliderArrowAll(this.slickAllToPrev)
    const { isFetching, photoModelId, date, photoModels, slidesToShow } = this.state
    const sliderSettings = {
      accessibility: false,
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow,
      slidesToScroll: 1,
      nextArrow: (
        <SliderArrowAllNext>
          <IconComponent icon={'chevron-right'}size="lg" />
        </SliderArrowAllNext>
      ),
      prevArrow: (
        <SliderArrowAllPrev>
          <IconComponent icon={'chevron-left'} size="lg" />
        </SliderArrowAllPrev>
      ),
    }

    const filters: FilterComponent[] = [
      {
        type: 'Select',
        value: photoModelId,
        disabled: isFetching,
        label: this.props.t('checklist.photosView.modelLabel'),
        id: 'select-shift',
        onChange: (name, value) => {
          if (value) {
            this.handleModelChange(value as string)
          }
        },
        options: {
          defaultOptionLabel:
            (!photoModelId && this.props.t('checklist.photosView.selectModelPlaceholder')) || undefined,
          defaultOptionDisabled: true,
          items: photoModels.map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        },
      },
      {
        id: 'select-date',
        type: 'Date',
        value: date ? mvDate.parseDateFromStringWithFormatting(date, 'dd/MM/yyyy').toISOString() : null,
        onChange: (name, value) => {
          if (value) {
            this.handleDateChange(new Date(value))
          }
        },
        label: this.props.t('checklist.photosView.dateLabel'),
        notClearable: true,
      },
    ]

    return (
      <div className="mv4iot-fe-checklist">
        <PageHeader title={this.props.t('checklist.photosView.title')} ref={this.state.carouselRef} />

        <ChecklistPhotosModal
          closeLabel={this.props.t('checklist.photosView.close')}
          visible={!!this.state.modalPhotos}
          title={this.state.modalPhotosTitle!}
          onClose={this.closePhotosModal}
          modalSliderRef={this.state.modalSliderRef}
          modalPhotos={this.state.modalPhotos}
          modalPhotoIndex={this.state.modalPhotoIndex}
        />

        <MainPageContent loading={isFetching}>
          <Filters fields={filters} />
          {this.state.error ? (
            <div className="alert alert-danger">{this.state.error}</div>
          ) : this.state.photos.length > 0 ? (
            this.state.photos.map(({ ref, id, title, userName, concludedDate, photos }) => {
              return (
                <div key={id} className="checklist-photos-carousel">
                  <h5>
                    {mvDate.getDateFromStringWithFormatting(concludedDate, 'dd/MM/yyyy HH:mm')} • {userName} •
                    <small className="text-muted ml-2">{title}</small>
                  </h5>
                  <Slider
                    ref={ref}
                    className={photos.length <= sliderSettings.slidesToShow ? 'slick-no-arrows' : ''}
                    {...sliderSettings}
                  >
                    {photos.map((photo, index) => (
                      <div
                        key={index}
                        className={`checklist-photo-slide${photo ? '' : ' disabled'}`}
                        onClick={() => photo && this.openPhotosInModal(photos, title, index)}
                      >
                        {photo ? (
                          <>
                            <Image
                              className="checklist-photo-image"
                              path={`/file/${photo.path}?w=640&h=480`}
                              alt={photo.description}
                              iconSize="sm"
                              category={photo.description || ''}
                              onImageLoaded={(infoImage: Partial<InfoImage>) => {
                                this.setState(prev => ({
                                  ...prev,
                                  infoImages: { ...(prev.infoImages || {}), [photo.path]: infoImage },
                                }))
                              }}
                            />
                            <div className="d-flex justify-content-between align-items-center pt-1">
                              {photo.description}
                              {(photo.notes || this.state.infoImages?.[photo.path]?.dateTimeOriginal) && (
                                <Tooltip
                                  trigger="mouseenter click"
                                  position="top"
                                  title={`${photo.notes && `${photo.notes} <br>` || ''}${this.props.t('checklist.photosView.dateTimeOriginal')}${this.state.infoImages?.[photo.path]?.dateTimeOriginal}`}
                                >
                                  <IconComponent icon={'info-circle'} size="xs" className="text-muted" />
                                </Tooltip>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="checklist-photo-image" />
                        )}
                      </div>
                    ))}
                  </Slider>
                </div>
              )
            })
          ) : (
            <Alert type={'warning'}>{this.props.t('checklist.photosView.noPhoto')}</Alert>
          )}
        </MainPageContent>
      </div>
    )
  }

  private handleModelChange(photoModelId: string) {
    this.setState({ photoModelId }, this.fetchPhotos)
  }

  private handleDateChange(date: Date) {
    this.setState({ date: mvDate.format(date, 'dd/MM/yyyy') }, this.fetchPhotos)
  }

  private handleError(error: Error) {
    this.setState({ isFetching: false, error: error && error.message })
  }

  private fetchPhotos() {
    const { photoModelId, date } = this.state
    if (photoModelId && date) {
      this.setState({ isFetching: true, error: null })
      this.props
        .fetchPhotos(photoModelId, mvDate.format(mvDate.parseDateFromStringWithFormatting(date, 'dd/MM/yyyy'), 'yyyy-MM-dd'))
        .then((list: ChecklistPhoto[]) => {
          const photoModelCheckMaxSizes = list.reduce((acc: number[], { checks }) => {
            const newArray: number[] = checks.map(({ photos }, index) =>
              !acc[index] || photos.length > acc[index] ? photos.length : acc[index]
            )
            return newArray.concat(acc.splice(newArray.length, acc.length))
          }, [])

          const datePhotos: ChecklistDatePhotos[] = list.map(({ id, title, user, concludedDate, checks }) => ({
            ref: React.createRef(),
            id,
            title,
            userName: user.displayName,
            concludedDate,
            photos: checks.reduce((acc: Array<SliderPhoto | null>, { description, notes, photos }, checkIndex) => {
              acc.push(
                ...new Array(photoModelCheckMaxSizes[checkIndex]).fill(null).map((_, index) =>
                  photos[index]
                    ? {
                        path: photos[index],
                        description,
                        notes,
                      }
                    : null
                )
              )
              return acc
            }, []),
          }))
          this.setState({ isFetching: false, photos: datePhotos })
        })
        .catch(this.handleError)
    }
  }

  private slickAllToNext(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.state.photos.forEach(({ ref }) => ref.current.slickNext())
  }

  private slickAllToPrev(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
    this.state.photos.forEach(({ ref }) => ref.current.slickPrev())
  }

  private openPhotosInModal(photos: Array<SliderPhoto | null>, modalPhotosTitle: string, photoIndex: number = 0) {
    const modalPhotos = photos.filter(photo => photo) as SliderPhoto[]
    this.setState({
      modalPhotos,
      modalPhotosTitle,
      modalPhotoIndex: modalPhotos.indexOf(photos[photoIndex] as SliderPhoto),
    })
    // Focus slide to enable keyboard accessibility
    setTimeout(() => this.state.modalSliderRef.current && this.state.modalSliderRef.current.focus(), 500)
  }

  private closePhotosModal() {
    this.setState({
      modalPhotos: null,
      modalPhotosTitle: null,
      modalPhotoIndex: null,
    })
  }

  private updateCarouselSlidesToShow() {
    //     const componentWidth = this.state.carouselRef.current.offsetWidth
    this.setState({
      slidesToShow: parseInt(`${window.innerWidth / 300}`, 10),
    })
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChecklistPhotos)))
