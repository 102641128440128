import * as React from 'react'
import { ChecklistAssetItem } from '../../../../types'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import TitleWithCompilationData from '../../widgets/TitleWithCompilationData/TitleWithCompilationData'

export interface Props {
  list: ChecklistAssetItem[]
  clickHandler: (item: ChecklistAssetItem) => Promise<void>
}


function SmallScreenView(props: Props) {
  return (
    <ul className="list-group list-group-flush cl-list">
      {props.list.map((item, index) => {
        const openedBySelf = item.model.checklistInstanceId
        const openedByOtherUser = item.model.differentUserOpenChecklist
        return (
          <li
            key={index}
            className={`
              list-group-item
              list-group-item-action
              d-flex flex-row align-items-center justify-content-between py-3 px-0 pl-3
              ${openedBySelf ? 'opened-by-self' : ''}
              ${openedByOtherUser ? 'opened-by-other-user' : ''}
            `}
            onClick={() => props.clickHandler(item)}
          >
            <span className='checklist-title'>
              {/* <h5 className="my-2">{item.model.title}</h5> */}
              <TitleWithCompilationData
                title={item.model.title}
                lastCompilationData={item.model.lastCompilationData}
                smallScreen={true}
              />
              {item.asset ? (
                <p className="text-muted mb-0">
                  <span className="checklist-assetdetail d-inline-block w-100 mb-0 text-truncate">{item.asset.description}</span>
                  <span className="checklist-assetcode mt-0">[{item.asset.code}]</span><br />
                </p>
              ) : null}
              {item.model.differentUserOpenChecklist ? (
                <p className="text-muted mt-1 mb-0">
                  {mvDate.getDateFromStringWithFormatting(item.model.differentUserOpenChecklist.createdDate, 'dd/MM/yyyy HH:mm')}
                  &nbsp;
                  {item.model.differentUserOpenChecklist.user.displayName}
                </p>
              ) : null}
            </span>
            <span className='checklist-arrow  pl-3 pr-1'>
              <IconComponent icon={'angle-right'} size="lg"/>
            </span>
          </li>
        )
      })}
    </ul>
  )
}

export default SmallScreenView
