import * as React from 'react'
import { ChecklistModel } from '../../../../types'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import IconComponent from '../../../../../inplant-components-fe/ui/components/MVIcon/Icon'
import TitleWithCompilationData from '../../widgets/TitleWithCompilationData/TitleWithCompilationData'

export interface Props {
  list: ChecklistModel[]
  clickHandler: (item: ChecklistModel) => Promise<void>
}

function SmallScreenView(props: Props) {
  return (
    <ul className="list-group list-group-flush cl-list">
      {props.list.map(item => {
        const openedBySelf = item.checklistInstanceId
        const openedByOtherUser = item.differentUserOpenChecklist
        return (
          <li
            key={item.id}
            className={`
              list-group-item
              list-group-item-action
              d-flex flex-row align-items-center justify-content-between py-3 px-0 pl-3
              ${openedBySelf ? 'opened-by-self' : ''}
              ${openedByOtherUser ? 'opened-by-other-user' : ''}
            `}
            onClick={() => props.clickHandler(item)}
          >
            <span className='checklist-title'>
              {/* <h5 className="mb-0">{item.title}</h5> */}
              <TitleWithCompilationData
                title={item.title}
                lastCompilationData={item.lastCompilationData}
                smallScreen={true}
              />
              {item.differentUserOpenChecklist ? (
                <p className="text-muted mt-1 mb-0">
                  {mvDate.getDateFromStringWithFormatting(item.differentUserOpenChecklist.createdDate, 'dd/MM/yyyy HH:mm')}
                  &nbsp;
                  {item.differentUserOpenChecklist.user.displayName}
                </p>
              ) : null}
            </span>
            <span className='checklist-arrow  pl-3 pr-1'>
              <IconComponent icon={'angle-right'} size="lg"/>
            </span>
          </li>
        )
      })}
    </ul>
  )
}

export default SmallScreenView
