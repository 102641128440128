import * as React from 'react'
import {connect} from 'react-redux'
import {withTranslation, WithTranslation} from 'react-i18next'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {Asset, ChecklistAssetDispatchProps} from '../../../../types'
import {checklistStartChecklist} from '../../../../redux/actions'
import {Button, PageHeader, Table} from '@mv-submodules/inplant-components-fe'
import MainPageContent from '@mv-submodules/inplant-components-fe/ui/components/Layout/MainPageContent'
import {checklistAssetFetchIndexNoRedux} from '@mv-submodules/inplant-checklist-fe-iblu/redux/actions/checklistAsset'
import TitleWithCompilationData from '../../widgets/TitleWithCompilationData/TitleWithCompilationData'
import FetchError from '@mv-submodules/inplant-coreadapter-fe/functions/fetch-wrapper/FetchError'
import { mvDate } from '../../../../../inplant-components-fe/mvfunctions/helpers/dateHelper'
import { IconComponent } from '../../../../../mvlabs-components-fe/ui/components'

const mapDispatchToProps = (dispatch: Function): ChecklistAssetDispatchProps => ({
  fetchIndex: (checklistModelId: string) => dispatch(checklistAssetFetchIndexNoRedux(checklistModelId)),
  startChecklist: (checklistModelId: string, assetId?: string) =>
    dispatch(checklistStartChecklist(checklistModelId, assetId)),
})

interface OwnState {
  fetching: boolean,
  error: boolean,
  assets: Asset[],
}

export type Props = RouteComponentProps & WithTranslation & ChecklistAssetDispatchProps

export class ChecklistAsset extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props)
    this.handleAssetClick = this.handleAssetClick.bind(this)
    this.state = {
      fetching: false,
      assets: [],
      error: false
    }
  }

  public componentDidMount() {
    this.setState({fetching: true})
    // @ts-ignore
    this.props.fetchIndex(this.props.match.params.checklistModelId)
      .then(assets => this.setState({assets}))
      .catch((error: FetchError) => {
        this.setState({error: true})
      })
      .finally(() => this.setState({
        fetching: false,
      }))
  }


  public async handleAssetClick(asset: Asset) {
    if (asset.checklistInstanceId) {
      return this.props.history.push(`/checklist/edit/${asset.checklistInstanceId}`)
    }
    try {
      // @ts-ignore
      const checklistInstanceId = await this.props.startChecklist(this.props.match.params.checklistModelId, asset.id)
      return this.props.history.push(`/checklist/edit/${checklistInstanceId}`)
    } catch (error) {
      // @ts-ignore
      return this.props.fetchIndex(this.props.match.params.checklistModelId)
    }
  }

  public render() {
    return (
      <React.Fragment>
        <PageHeader
          isMobile={window.innerWidth < 768}
          title={this.props.t('checklist.asset.title')}
          backButton={true}
          backButtonOnClick={() => this.props.history.goBack()}
        />
        <MainPageContent>
          {window.innerWidth > 768 ? (
            <Table
              data={this.state.assets}
              manual={true}
              pageSize={this.state.assets.length}
              minRows={this.state.assets.length - 1}
              isFetching={this.state.fetching}
              manualIsFetching={true}
              showPagination={false}
              rowsText={''}
              ofText={''}
              noDataText={this.props.t('checklist.asset.table.noData')}
              getTrProps={(state: any, row: any) => {
                if (!row) {
                  return {}
                }
                const openedBySelf = row.original.checklistInstanceId
                const openedByOtherUser = row.original.differentUserOpenChecklist
                if (openedBySelf) {
                  return {className: 'opened-by-self'}
                }
                if (openedByOtherUser) {
                  return {className: 'opened-by-other-user'}
                }
                return {}
              }}
              columns={[
                {
                  Header: this.props.t('checklist.asset.table.description'),
                  accessor: 'description',
                  Cell: data => {
                    const asset:Asset = data.original
                    return <TitleWithCompilationData title={`[${asset.code}] ${asset.description}`} lastCompilationData={asset.lastCompilationData} />
                  },
                },
                {
                  Header: this.props.t('checklist.asset.table.actions'),
                  sortable: false,
                  style: {overflow: 'visible'},
                  Cell: data => {
                    return (
                      <div className="text-right">
                        {data.original.differentUserOpenChecklist ? (
                          <span
                            className="text-info"
                            aria-label={`
                                  ${mvDate.getDateFromStringWithFormatting(data.original.differentUserOpenChecklist.createdDate, 'dd/MM/yyyy HH:mm')}
                                  ${data.original.differentUserOpenChecklist.user.displayName}
                                `}
                            data-balloon-pos="left"
                          >
                            <IconComponent icon={'info-circle'} className="mr-3" size="lg"/>
                          </span>
                        ) : null}
                        <Button
                          variant={"primary"}
                          onClick={() => this.handleAssetClick(data.original)}
                          label={this.props.t('checklist.asset.table.compile')}/>

                      </div>
                    )
                  },
                },
                ]}
                />
                ) : (

                <ul className="list-group list-group-flush cl-list">
              {this.state.assets.map((item, index) => {
                const openedBySelf = item.checklistInstanceId
                const openedByOtherUser = item.differentUserOpenChecklist
                return (
                <li
                key={index}
                className={`
                      list-group-item
                      list-group-item-action
                      d-flex flex-row align-items-center justify-content-between py-3 px-0 pl-3
                      ${openedBySelf ? 'opened-by-self' : ''}
                      ${openedByOtherUser ? 'opened-by-other-user' : ''}
                    `
              }
                onClick={() => this.handleAssetClick(item)}
                >
                <span className='checklist-title'>
              {
                <p className="text-muted mb-0">
                <span
                className="checklist-assetdetail d-inline-block w-100 mb-0 text-truncate">{item.description}</span>
                <span className="checklist-assetcode mt-0">[{item.code}]</span><br/>
                </p>
              }
              {openedByOtherUser ? (
                <p className="text-muted mt-1 mb-0">
              {mvDate.getDateFromStringWithFormatting(openedByOtherUser.createdDate, 'dd/MM/yyyy HH:mm')}
                &nbsp;
              {openedByOtherUser.user.displayName}
                </p>
                ) : null}
                </span>
                <span className='checklist-arrow  pl-3 pr-1'>
                <IconComponent icon={'angle-right'} size="lg"/>
                </span>
                </li>
                )
              })}
                </ul>
                )}
            </MainPageContent>
            </React.Fragment>
            )
          }
}


export default withRouter<any, any>(
  connect(
    undefined,
    mapDispatchToProps,
  )(withTranslation()(ChecklistAsset)))
