import { i18next as i18n, i18nextInit } from '@mv-submodules/inplant-core-fe/i18n/i18n'
import core from '@mv-submodules/inplant-core-fe/i18n'
import checklist from '@mv-submodules/inplant-checklist-fe-iblu/i18n'
import director from '../i18n'
import overwrites from '../i18n/overwrite'
import user from '@mv-submodules/inplant-user-fe/i18n'
import components from '@mv-submodules/inplant-components-fe/i18n'
import coreAdapter from '../../inplant-coreadapter-fe/i18n'
import chronoFrame from '@mv-submodules/inplant-chronoframe-fe/i18n'
import assetManager from '@mv-submodules/inplant-asset-manager-fe/i18n'

import { extend } from '@mv-submodules/inplant-core-fe/functions/objects'


i18nextInit({
  // @ts-ignore
  resources: extend(true, {
    en: {
      translation: {
        ...director.en,
        ...core.en,
        ...checklist.en,
        ...user.en,
        ...chronoFrame.en,
        ...components.en,
        ...coreAdapter.en,
        ...assetManager.en
      },
    },
    it: {
      translation: {
        ...director.it,
        ...core.it,
        ...checklist.it,
        ...user.it,
        ...chronoFrame.it,
        ...components.it,
        ...coreAdapter.it,
        ...assetManager.it
      },
    },
  }, {
    en: {
      translation: {
        ...overwrites.en,
      },
    },
    it: {
      translation: {
        ...overwrites.it,
      },
    },
  }),
})

export default i18n
